<template>
  <div class="page-container log">
    <div class="title">{{ $t("route.log") }}</div>
    <div class="flex filter-container">
      <div class="filter-item">
        <el-input
          v-model="listQuery.keyword"
          :placeholder="$t('form.placeholder_search')"
          :clearable="listQuery.keyword.length > 0"
          @keyup.enter="handleFilter"
          @clear="handleFilter"
        />
      </div>
      <!-- <div class="filter-item"></div> -->
      <date-selector
        class="filter-item"
        v-model:selectedDate="listQuery.begin"
        :placeholder="$t('form.placeholder_beginDate')"
      />
      <date-selector
        class="filter-item"
        v-model:selectedDate="listQuery.end"
        :placeholder="$t('form.placeholder_endDate')"
      />

      <!-- <el-select v-model="listQuery.categoryId" @change="handleFilter">        
        <el-calendar v-model="listQuery.categoryId"> </el-calendar>
      </el-select> -->
      <el-button
        class="filter-item button"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
      >
        {{ $t("form.search") }}
      </el-button>
    </div>
    <el-table
      v-loading="isLoading"
      class="table"
      :data="logList"
      border
      fit
      highlight-current-row
    >
      <el-table-column
        prop="creationTime"
        :label="$t('table.time')"
        align="center"
        min-width="80"
      />
      <el-table-column
        prop="operation"
        :label="$t('table.operation')"
        align="center"
        min-width="60"
      />
      <el-table-column
        prop="operator"
        :label="$t('table.operator')"
        align="center"
        min-width="80"
      />
      <el-table-column
        prop="source"
        :label="$t('table.source')"
        align="center"
        min-width="60"
      />
      <el-table-column
        prop="target"
        :label="$t('table.target')"
        align="center"
        min-width="60"
      />
    </el-table>
    <el-pagination
      v-show="total > 0"
      class="pagination"
      layout="total, prev, pager, next"
      :total="total"
      v-model:pageSize="listQuery.pagesize"
      v-model:currentPage="listQuery.page"
      @current-change="getLogList"
    />
  </div>
</template>

<script>
import { ref, reactive } from "vue";

import dateSelector from "@/components/DateSelector";

import { getLogs } from "@/api/log";

const lang = "cn";

export default {
  name: "Log",
  components: {
    dateSelector,
  },
  setup() {
    const isLoading = ref(true);

    const logList = ref([]);
    const total = ref(0);
    const listQuery = reactive({
      page: 1,
      pagesize: 10,
      keyword: "",
      begin: null,
      end: null,
    });

    const getLogList = async () => {
      isLoading.value = true;

      const response = await getLogs(listQuery);
      // console.log("###", response.content);
      total.value = response.content.totalNumberOfRecords;
      logList.value = response.content.results;
      // console.log("### ", logList.value);

      isLoading.value = false;
    };

    const handleFilter = () => {
      listQuery.page = 1;
      getLogList();
    };

    getLogList();

    return {
      lang,
      isLoading,
      logList,
      total,
      listQuery,

      getLogList,
      handleFilter,
    };
  },
};
</script>

<style lang="less">
.el-pagination {
  .btn-prev {
    background-color: transparent !important;
  }
  .btn-next {
    background-color: transparent !important;
  }
  .number {
    background-color: transparent !important;
  }
}
</style>

<style lang="less" scoped>
.page-container.log {
  .filter-container {
    padding-bottom: 10px;

    .filter-item {
      min-width: 100px;
      width: 200px;
      margin-bottom: 5px;
      margin-right: 5px;
    }

    .filter-item.button {
      width: auto;
    }
  }

  .table {
    width: 100%;
  }

  .pagination {
    padding: 32px 16px;
  }
}
</style>
