<template>
  <el-card class="component-container card-block">
    <template v-slot:header>
      <div class="card-header">
        <p class="card-block-text title">{{ title }}</p>
      </div>
    </template>
    <div class="flex wrap card-content">
      <div
        v-for="(project, index) in list"
        :key="index"
        class="project-container"
      >
        <el-tooltip
          popper-class="tooltip"
          effect="dark"
          placement="top-start"
          :open-delay="700"
          :content="project.description[locale]"
          :disabled="project.description[locale].length === 0"
        >
          <a
            v-if="project.url"
            class="flex-row project link clickable"
            :href="project.url"
          >
            <div class="cover-container">
              <img class="icon" :src="getCover(project.cover)" />
            </div>
            <div class="text-container">
              <p class="text-ellipsis-2 card-block-text title">
                {{ project.name[locale] }}
              </p>
              <span class="card-block-text url">{{
                getDisplayUrl(project.url) || project.subtitle[locale]
              }}</span>
            </div>
          </a>
          <div v-else class="flex-row project clickable">
            <div class="cover-container">
              <img class="icon" :src="getCover(project.cover)" />
            </div>
            <div class="text-container">
              <p class="text-ellipsis-2 card-block-text title">
                {{ project.name[locale] }}
              </p>
              <span class="card-block-text url">{{
                getDisplayUrl(project.url) || project.subtitle[locale]
              }}</span>
            </div>
          </div>
        </el-tooltip>
      </div>
    </div>
  </el-card>
</template>

<script>
import { computed, toRefs } from "vue";
import store from "@/store";

export default {
  name: "Block_Cards",
  props: {
    title: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup(props) {
    const { title, list } = toRefs(props);
    const locale = computed(() => {
      return store.getters.lang;
    });
    const getCover = (cover) => {
      return cover || require("@/images/cover_default.png");
    };
    const getDisplayUrl = (url) => {
      if (!url) return null;

      let ret = url;
      if (ret.startsWith("https://")) {
        ret = ret.substring(8);
      }
      if (ret.startsWith("http://")) {
        ret = ret.substring(7);
      }
      if (ret.endsWith("/")) {
        ret = ret.substring(0, ret.length - 1);
      }

      return ret;
    };

    return {
      locale,
      title,
      list,
      getCover,
      getDisplayUrl,
    };
  },
};
</script>


<style lang="less">
.el-card__header {
  padding: 0 24px;
  border: none;
}
</style>

<style lang="less" scoped>
.component-container.card-block {
  margin-bottom: 16px;
  border-radius: 16px;
  border-width: 0;
  box-shadow: none;

  .card-header {
    padding: 16px 0;
    border-bottom: 1px solid rgb(245, 245, 245);

    .title {
      line-height: 1.5;
      margin: 0;
    }
  }

  .card-content {
    width: calc(100% + 8px);
    margin: -4px;
    box-sizing: border-box;

    .project-container {
      margin: 0;
      padding: 8px;
      max-width: 50%;
      flex-basis: 50%;
      box-sizing: border-box;

      .link {
        text-decoration: none;
      }

      .project {
        border-radius: 8px;

        .cover-container {
          margin-right: 8px;
        }

        .text-container {
          overflow: hidden;

          .title {
            line-height: 1.5;
            margin: 0;
          }

          .url {
            line-height: 1.66;
            margin: 0;
            word-break: break-all;
          }
        }
      }
      .project:focus {
        outline: none;
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .component-container.card-block {
    .project-container {
      max-width: 33% !important;
      flex-basis: 33% !important;

      .project {
        padding: 8px;
      }
      .project:hover {
        background-color: #f8f8f8;

        .title {
          color: #ff7828;
        }
      }
    }
  }
}

@media screen and (min-width: 960px) {
  .component-container.card-block {
    .project-container {
      max-width: 25% !important;
      flex-basis: 25% !important;
    }
  }
}

.card-block-text.title {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
}
.card-block-text.url {
  font-size: 0.75rem;
  color: rgb(153, 153, 153);
}
</style>


