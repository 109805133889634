import Cookies from 'js-cookie'

const TokenKey = 'conflux123_token'
const LanguageKey = 'conflux123_lang'
const NameKey = 'conflux123_name'
const AvatarKey = 'conflux123_avatar'

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  if (token)
    Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

export function getLanguage () {
  return Cookies.get(LanguageKey)
}

export function setLanguage (language) {
  if (language)
    Cookies.set(LanguageKey, language)
}