import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './languages'

import '@/permission' // permission control

import 'element3/lib/theme-chalk/index.css'
import Element3 from 'element3'

import '@/styles/index.less' // global css

import Network from './network'
Network.init()

createApp(App).use(store).use(router).use(i18n).use(Element3).mount('#app')