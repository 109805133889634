<template>
  <div class="page-container category">
    <div class="title">{{ $t("route.category") }}</div>
    <div class="flex-row-reverse filter-container">
      <el-button
        class="filter-item button"
        type="primary"
        icon="el-icon-plus"
        @click="handleAdd"
        >{{ $t("category.add") }}</el-button
      >
    </div>
    <el-table
      v-loading="isLoading"
      class="table"
      :data="categoryList"
      border
      fit
      highlight-current-row
    >
      <el-table-column :label="$t('table.name')" align="center" min-width="120">
        <template v-slot="{ row }">
          <span>{{ row.name ? row.name[locale] : "" }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        :label="$t('table.isDefault')"
        align="center"
        min-width="40"
      >
        <template v-slot="{ row }">
          <span>{{ $t("common." + (row.isDefault ? "yes" : "no")) }}</span>
        </template>
      </el-table-column> -->
      <el-table-column
        :label="$t('table.action')"
        align="center"
        min-width="60"
      >
        <template v-slot="{ row, index_ }">
          <el-button
            type="text"
            size="small"
            :disabled="row.isDefault || index_ === 0"
            @click="handleReorder(row.id, index_ - 1)"
          >
            {{ $t("table.up") }}
          </el-button>
          <el-button
            type="text"
            size="small"
            :disabled="row.isDefault || index_ >= categoryList.length - 2"
            @click="handleReorder(row.id, index_ + 1)"
          >
            {{ $t("table.down") }}
          </el-button>
          <el-button type="text" size="small" @click="handleEdit(row)">
            {{ $t("table.edit") }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="space"></div>
    <el-dialog
      v-model:visible="isShowDialog"
      :title="categoryQuery.title"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @closed="handleDialogClosed"
    >
      <template v-slot:default>
        <category-form
          :category="categoryQuery.data"
          @onSubmit="handleSubmit"
          @onCancel="isShowDialog = false"
        />
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import store from "@/store";

import { MapCategoryList } from "@/utils/mapper";
import {
  getCategories,
  reorderCategory,
  addCategory,
  updateCategory,
} from "@/api/category";

import categoryForm from "@/components/DataForm/Category";

export default {
  name: "Category",
  components: {
    categoryForm,
  },
  setup() {
    const { t } = useI18n();
    const locale = computed(() => {
      return store.getters.lang;
    });

    const isLoading = ref(true);
    const isShowDialog = ref(false);

    const categoryList = ref([]);
    const categoryQuery = ref({});

    const getCategoryList = async () => {
      isLoading.value = true;

      const response = await getCategories();
      // console.log("###", response.content);
      categoryList.value = MapCategoryList(response.content);
      // console.log("### ", categoryList.value);

      isLoading.value = false;
    };

    const handleReorder = async (categoryId, index) => {
      await reorderCategory({
        id: categoryId,
        index,
      });
      getCategoryList();
    };
    const handleAdd = () => {
      categoryQuery.value = {
        title: t("category.add"),
        data: null,
      };
      isShowDialog.value = true;
    };
    const handleEdit = (item) => {
      categoryQuery.value = {
        title: t("category.edit"),
        data: item,
      };
      isShowDialog.value = true;
    };
    const handleSubmit = async (e) => {
      // console.log("### ", e);
      if (e.data.id) {
        await updateCategory(e.data);
      } else {
        await addCategory(e.data);
      }
      isShowDialog.value = false;
      getCategoryList();
    };
    const handleDialogClosed = () => {
      categoryQuery.value = {};
    };

    getCategoryList();

    return {
      locale,
      isLoading,
      isShowDialog,

      categoryList,
      categoryQuery,

      handleReorder,
      handleAdd,
      handleEdit,
      handleSubmit,
      handleDialogClosed,
    };
  },
};
</script>

<style lang="less" scoped>
.page-container.category {
  .filter-container {
    padding-bottom: 10px;

    .filter-item {
      min-width: 100px;
      width: 200px;
      margin-bottom: 5px;
      margin-right: 5px;
    }
    .filter-item.button {
      width: auto;
    }
  }

  .table {
    width: 100%;
  }

  .space {
    height: 20px;
  }
}
</style>
