import { Conflux, format } from "js-conflux-sdk"
import store from '@/store'

const instance = new Conflux({ url: process.env.VUE_APP_CONFLUX_ADDR, networkId: Number(process.env.VUE_APP_NETWORK_ID) })

window.confluxJS = new Conflux({ url: process.env.VUE_APP_CONFLUX_ADDR, networkId: Number(process.env.VUE_APP_NETWORK_ID) })
window.confluxJS.provider = window.conflux

const init = () => {
  if (!window.conflux){
    return
  }else if (!window.conflux.isFluent) {
    return
  }
    


  watchAccount(10)

  window.conflux.on('accountsChanged', function (accounts) {
    if (accounts.length === 0)
      handleAccountChanged(accounts)
  })
}

const login = async () => {
  if (window.conflux) {
    if (window.conflux.isFluent) {
    // 用户已经安装Fluent
      try {
        const accounts = await window.conflux.request({ method: 'cfx_requestAccounts' })
        // console.log('### ', accounts)
        if (accounts.length > 0) {
          store.dispatch("user/initUserAddress", accounts[0])
          return true
        }
        else
          return false
      } catch (error) {
        // console.log("### ", error)
        return false
      }

    } else {
      // 用户未安装Fluent
      window.open("https://www.fluentwallet.com/", "_blank")
      return null
    }
  }else{
      // 用户未安装Fluent
      window.open("https://www.fluentwallet.com/", "_blank")
      return null
  }
}

const watchAccount = async (count) => {
  if (count === 0) return

  const accounts = await getCurrentAccount()
  if (accounts.length > 0 && store.getters.userAddress !== accounts[0]) {
    handleAccountChanged(accounts)
  }
  else {
    setTimeout(() => {
      watchAccount(count - 1)
    }, 100)
  }
}

const getCurrentAccount = async () => {
  const accounts = await window.conflux.request({ method: 'cfx_accounts' })
  // TIPS: 正常情况下accounts应为数组，多一步判断兼容其他conflux钱包
  return Array.isArray(accounts) ? accounts : accounts.result
}

const handleAccountChanged = (accounts) => {
  // console.log("### accountsChanged", accounts)
  if (accounts.length > 0) {
    store.dispatch("user/initUserAddress", accounts[0])
  }
  else {
    store.dispatch("user/logout")
  }
}

export default {
  instance,

  init,
  login,
  formatAddress: (address) => {
    return format.address(address, Number(process.env.VUE_APP_NETWORK_ID))
  },

  sendTransaction: (transaction, options = {}) => {
    options["from"] = store.getters.userAddress
    if (process.env.VUE_APP_GASPRICE)
      options["gasPrice"] = process.env.VUE_APP_GASPRICE
    return transaction.sendTransaction(options).executed()
  },
}