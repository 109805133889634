<template>
  <el-breadcrumb class="component-container breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item, index) in levelList" :key="index">
        <!-- <span
          v-if="item.redirect === 'noRedirect' || index == levelList.length - 1"
          class="no-redirect"
          >{{ item.meta.title }}</span
        >
        <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a> -->
        <a @click.prevent="handleLink(item)">
          {{ $t("route." + item.meta.id) }}
        </a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import { ref, watchEffect } from "vue";
import router from "@/router";

export default {
  name: "Breadcrumb",
  setup() {
    const levelList = ref([]);

    const getBreadcrumb = (route) => {
      const matched = route.matched.filter((p) => p.meta && p.meta.title);
      // const first = matched[0];
      // if (!this.isDashboard(first)) {
      //   matched = [{ path: "/dashboard", meta: { title: "首页" } }].concat(
      //     matched
      //   );
      // }
      levelList.value = matched.filter(
        (p) => p.meta && p.meta.title && p.meta.breadcrumb !== false
      );
    };

    watchEffect(() => {
      getBreadcrumb(router.currentRoute.value);
    });

    const handleLink = () => {
      const { redirect, path } = item;
      router.push(redirect || path);
    };
    return {
      levelList,
      handleLink,
    };
  },
};
</script>

<style lang="less" scoped>
.component-container.breadcrumb {
  display: inline-block;
  margin-left: 8px;
  line-height: 50px;
  font-size: 14px;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>