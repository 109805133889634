<template>
  <div class="page-container admin">
    <div class="title">{{ $t("route.admin") }}</div>
    <div class="flex-row-reverse filter-container">
      <el-button
        class="filter-item button"
        type="primary"
        icon="el-icon-plus"
        @click="handleAdd"
        >{{ $t("admin.add") }}</el-button
      >
    </div>
    <el-table
      v-loading="isLoading"
      class="table"
      :data="adminList"
      border
      fit
      highlight-current-row
    >
      <el-table-column
        prop="address"
        :label="$t('table.address')"
        align="center"
        min-width="120"
      />
      <el-table-column
        prop="lastLogin"
        :label="$t('table.lastLogin')"
        align="center"
        min-width="60"
      />
      <el-table-column
        :label="$t('table.action')"
        align="center"
        min-width="60"
      >
        <template v-slot="{ row }">
          <el-button type="text" size="small" @click="handleRemove(row)">
            {{ $t("table.remove") }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="space"></div>
    <el-dialog
      v-model:visible="isShowDialog"
      :title="adminQuery.title"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @closed="handleDialogClosed"
    >
      <template v-slot:default>
        <admin-form
          :admin="adminQuery.data"
          @onSubmit="handleSubmit"
          @onCancel="isShowDialog = false"
        />
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import { Msgbox, Message } from "element3";
import adminForm from "@/components/DataForm/Admin";

import { getAdmins, addAdmin, removeAdmin } from "@/api/authentication";

export default {
  name: "Admin",
  components: {
    adminForm,
  },
  setup() {
    const { t } = useI18n();

    const isLoading = ref(true);
    const isShowDialog = ref(false);

    const adminList = ref([]);
    const adminQuery = ref({});

    const getAdminList = async () => {
      isLoading.value = true;

      const response = await getAdmins();
      // console.log("###", response.content);
      adminList.value = response.content;
      console.log("### ", adminList.value);

      isLoading.value = false;
    };

    const handleAdd = () => {
      adminQuery.value = {
        title: t("admin.add"),
        data: { address: "" },
      };
      isShowDialog.value = true;
    };
    const handleRemove = async (item) => {
      try {
        await Msgbox.confirm(
          t("admin.remove_confirm") + item.address,
          t("common.hint"),
          {
            confirmButtonText: t("common.sure"),
            cancelButtonText: t("common.cancel"),
            type: "warning",
          }
        );
        await removeAdmin({ address: item.address });
        Message({
          type: "success",
          message: t("admin.remove_completed"),
        });
        getAdminList();
      } catch (error) {
        console.log("### ", error);
      }
    };
    const handleSubmit = async (e) => {
      // console.log("### ", e);
      await addAdmin(e.data);
      isShowDialog.value = false;
      getAdminList();
    };
    const handleDialogClosed = () => {
      adminQuery.value = {};
    };

    getAdminList();

    return {
      isLoading,
      isShowDialog,

      adminList,
      adminQuery,

      handleAdd,
      handleRemove,
      handleSubmit,
      handleDialogClosed,
    };
  },
};
</script>

<style lang="less" scoped>
.page-container.admin {
  .filter-container {
    padding-bottom: 10px;

    .filter-item {
      min-width: 100px;
      width: 200px;
      margin-bottom: 5px;
      margin-right: 5px;
    }
    .filter-item.button {
      width: auto;
    }
  }

  .table {
    width: 100%;
  }

  .space {
    height: 20px;
  }
}
</style>
