const getters = {
  sidebar: state => state.layout.sidebar,
  isMobile: state => state.layout.isMobile,
  lang: state => state.layout.language,
  token: state => state.user.token,

  userName: state => state.user.token,
  userAvatar: state => state.user.avatar,
  userAddress: state => state.user.address
}

export default getters
