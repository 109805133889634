<template>
  <div class="component-container data-admin">
    <el-form
      ref="form"
      class="form"
      :model="adminForm"
      :rules="rules"
      label-width="100"
    >
      <el-form-item :label="$t('form.address')" prop="address">
        <el-input v-model="adminForm.address"></el-input>
      </el-form-item>
      <el-form-item class="flex-row-reverse actions">
        <el-button @click="handleCancel">{{ $t("common.cancel") }}</el-button>
        <el-button type="primary" @click="handleSubmit">
          {{ $t("common.sure") }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { ref, reactive, watchEffect, toRefs } from "vue";
import { useI18n } from "vue-i18n";

import { Message } from "element3";

export default {
  name: "DataForm_Admin",
  props: {
    admin: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  emits: ["onSubmit", "onCancel"],
  setup(props, context) {
    const { t } = useI18n();
    const { admin } = toRefs(props);

    const form = ref(null);
    const adminForm = reactive({});
    watchEffect(() => {
      adminForm.address = admin.address;
    });
    const rules = {
      address: [
        { required: true, message: t("admin.address_input"), trigger: "blur" },
      ],
    };

    const handleSubmit = async () => {
      // try {
      //   await form.value.validate();
      //   console.log("### adminForm: ", adminForm);
      // } catch (error) {
      //   console.log("### ", error);
      // }
      if (!adminForm.address) {
        Message({
          showClose: true,
          message: t("admin.address_input"),
          type: "warning",
        });
        return;
      }
      context.emit("onSubmit", { data: Object.assign({}, adminForm) });
    };

    const handleCancel = () => {
      context.emit("onCancel");
    };
    const handleReset = () => {
      form.value.resetFields();
    };

    return {
      form,

      adminForm,
      rules,

      handleSubmit,
      handleCancel,
    };
  },
};
</script>

<style lang="less" scoped>
.component-container.data-admin {
  height: 100%;
  width: 100%;
  overflow-y: auto;

  .form {
    .actions {
      margin-bottom: 0;
    }
  }
}
</style>