<template>
  <div class="page-container flex-column center login">
    <img class="logo" src="@/images/cover_default.png" />
    <div class="flex center address">
      <!-- <span class="login-text label">帐号：</span> -->
      <el-input
        v-model="userAddress"
        :placeholder="$t('form.placeholder_address')"
        readonly
      />
    </div>
    <div>
      <el-button
        class="button"
        type="primary"
        :loading="!userAddress && isLogging"
        :disabled="userAddress !== null"
        @click="handleAddress"
      >
        {{ addressText }}
      </el-button>
    </div>
    <div>
      <el-button
        class="button"
        type="primary"
        :loading="userAddress && isLogging"
        :disabled="userAddress === null"
        @click="handleLogin"
      >
        {{ loginText }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { ref, computed, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import store from "@/store";
import router from "@/router";

import Network from "@/network";
import AdminCon from "@/contracts/admin";
import { getNonce, login } from "@/api/authentication";

export default {
  name: "Login",
  setup() {
    const { t } = useI18n();

    const isLogging = ref(false);
    const addressText = ref(t("login.address_default"));
    const loginText = ref(t("login.login_default"));
    const redirect = ref(null);
    const userAddress = computed(() => {
      return store.getters.userAddress;
    });

    watchEffect(() => {
      const route = router.currentRoute.value;
      redirect.value = route.query && route.query.redirect;
    });

    const handleAddress = async () => {
      isLogging.value = true;
      addressText.value = t("login.address_running");

      await Network.login();
      addressText.value = t("login.address_default");
      isLogging.value = false;
    };

    const handleLogin = async () => {
      isLogging.value = true;
      loginText.value = t("login.login_running");

      const { content } = await getNonce({ address: userAddress.value });
      await AdminCon.login(content.remoteNonce);
      const response = await login({
        address: userAddress.value,
        sessionToken: content.sessionToken,
      });
      store.dispatch("user/initUserDetail", response.content);
      router.push(decodeURIComponent(redirect.value));

      loginText.value = t("login.login_default");
      isLogging.value = false;
    };

    return {
      isLogging,
      userAddress,
      addressText,
      loginText,
      handleAddress,
      handleLogin,
    };
  },
};
</script>

<style lang="less">
.page-container.login {
  .el-input {
    input {
      cursor: unset;
    }
  }
}
</style>

<style lang="less" scoped>
.page-container.login {
  height: 100vh;
  padding: 0;
  background: #2d3a4b;
  overflow: hidden;

  .logo {
    height: 160px;
    width: 160px;
    margin-bottom: 50px;
  }

  .address {
    width: 400px;
    margin-bottom: 26px;

    .label {
      min-width: 50px;
    }
  }

  .button {
    margin-bottom: 16px;
    width: 200px;
  }
}

.login-text.label {
  font-size: 16px;
  color: white;
}
</style>
