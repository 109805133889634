<template>
  <div class="component-container data-project">
    <el-form
      ref="form"
      class="form"
      :model="projectForm"
      :rules="rules"
      label-width="100"
    >
      <el-form-item :label="$t('form.category')" prop="categoryId">
        <el-select v-model="projectForm.categoryId">
          <el-option
            v-for="item in displayCategoryList"
            :key="item.id"
            :label="item.name[locale]"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-row>
        <el-col class="flex center col upload" :span="8">
          <el-upload
            v-loading="isUploading"
            action=""
            :show-file-list="false"
            :auto-upload="false"
            :limit="1"
            :on-change="handleUpload"
          >
            <img v-if="displayCover" class="image" :src="displayCover" />
            <div v-else class="uploader-container">
              <i class="el-icon-plus icon"></i>
            </div>
          </el-upload>
        </el-col>
        <el-col class="col" :span="16">
          <el-form-item :label="$t('form.url')" prop="url">
            <el-input v-model="projectForm.url"></el-input>
          </el-form-item>
          <el-form-item :label="$t('form.imageurl')" prop="imageUrl">
            <el-input v-model="projectForm.imageUrl"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col v-for="lang in languages" :key="lang" class="col" :span="8">
          <el-form-item
            :label="$t('form.name', [$t('language.' + lang)])"
            :prop="'title' + lang"
          >
            <el-input v-model="projectForm['title' + lang]"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('form.subtitle', [$t('language.' + lang)])"
            :prop="'subtitle' + lang"
          >
            <el-input v-model="projectForm['subtitle' + lang]"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('form.contact', [$t('language.' + lang)])"
            :prop="'contact' + lang"
          >
            <el-input v-model="projectForm['contact' + lang]"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('form.description', [$t('language.' + lang)])"
            :prop="'description' + lang"
          >
            <el-input
              v-model="projectForm['description' + lang]"
              type="textarea"
              :rows="3"
            ></el-input>
          </el-form-item>
          <el-form-item :prop="'show' + lang">
            <el-checkbox v-model="projectForm['show' + lang]">
              {{ $t("form.show", [$t("language." + lang)]) }}
            </el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item class="flex-row-reverse actions">
        <el-button @click="handleCancel">{{ $t("common.cancel") }}</el-button>
        <el-button type="primary" @click="handleSubmit">
          {{ $t("common.sure") }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { Message } from "element3";

import { ref, reactive, computed, watchEffect, toRefs } from "vue";
import { useI18n } from "vue-i18n";
import store from "@/store";
import { languages } from "@/config";
import { MapProjectFormData } from "@/utils/mapper";
import { uploadImage } from "@/api/file";

export default {
  name: "DataForm_Project",
  props: {
    categoryList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    project: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  emits: ["onSubmit", "onCancel"],
  setup(props, context) {
    const { t } = useI18n();
    const { categoryList, project } = toRefs(props);
    const locale = computed(() => {
      return store.getters.lang;
    });

    const isUploading = ref(false);

    const displayCategoryList = ref([]);
    displayCategoryList.value = categoryList.value.filter((p) => p.id !== null);
    // console.log("### ", displayCategoryList.value);

    const form = ref(null);
    const projectForm = reactive({});
    const displayCover = ref(null);
    watchEffect(() => {
      MapProjectFormData(project.value, projectForm);
      displayCover.value = project.value && project.value.cover;
    });
    const rules = {
      categoryId: [
        {
          required: true,
          message: t("project.category_select"),
          trigger: "blur",
        },
      ],
      // url: [
      //   { required: true, message: t("project.url_input"), trigger: "blur" },
      // ],
    };
    languages.forEach((p) => {
      rules["name" + p] = [
        {
          required: true,
          message: t("project.title_input", [t("language." + p)]),
          trigger: "blur",
        },
      ];
    });

    const uploadCover = async (file) => {
      isUploading.value = true;

      const data = new FormData();
      data.append("File", file);

      try {
        const response = await uploadImage(data, (e) => {
          if (e.lengthComputable) {
            // track.percentage = ((e.loaded / e.total) * 100).toFixed(1);
          }
        });
        // console.log(response.content.fileId);
        projectForm.imageSource = response.content.fileId;
        // console.log("### ", projectForm.imageSource);
        displayCover.value = URL.createObjectURL(file);
      } catch (error) {
        Message.error(error);
      } finally {
        isUploading.value = false;
      }
    };

    const handleUpload = async (file) => {
      const typeList = ["image/jpeg", "image/png"];
      if (typeList.indexOf(file.raw.type) === -1) {
        Message.error(t("project.cover_type"));
      }
      if (file.size / 1024 / 1024 > 2) {
        Message.error(t("project.cover_size"));
      }
      uploadCover(file.raw);
    };

    const handleSubmit = () => {
      // try {
      //   await form.value.validate();
      //   console.log("### projectForm: ", projectForm);
      // } catch (error) {
      //   console.log("### ", error);
      // }
      if (!projectForm.categoryId) {
        Message({
          showClose: true,
          message: t("project.category_select"),
          type: "warning",
        });
        return;
      }
      // if (!projectForm.url) {
      //   Message({
      //     showClose: true,
      //     message: t("project.url_input"),
      //     type: "warning",
      //   });
      //   return;
      // }

      let result = true;
      languages.forEach((p) => {
        if (result && !projectForm["title" + p]) {
          Message({
            showClose: true,
            message: t("category.title_input", [t("language." + p)]),
            type: "warning",
          });
          result = false;
        }
      });
      if (result) context.emit("onSubmit", { data: { ...projectForm } });
    };

    const handleCancel = () => {
      context.emit("onCancel");
    };
    // const handleReset = () => {
    //   form.value.resetFields();
    // };

    return {
      languages,

      locale,
      form,

      isUploading,
      displayCategoryList,
      projectForm,
      rules,
      displayCover,

      handleUpload,
      handleSubmit,
      handleCancel,
    };
  },
};
</script>

<style lang="less" scoped>
.component-container.data-project {
  height: 100%;
  width: 100%;
  overflow-y: auto;

  .form {
    .col {
      padding: 0 5px;
    }

    .upload {
      min-width: 180px;

      .uploader-container {
        width: 178px;
        height: 178px;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        .icon {
          font-size: 28px;
          color: #8c939d;
          line-height: 178px;
          text-align: center;
        }
      }
      .uploader-container:hover {
        border-color: #409eff;
      }

      .image {
        display: block;
        width: 178px;
        height: 178px;
      }
    }

    .actions {
      margin-bottom: 0;
    }
  }
}
</style>