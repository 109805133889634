import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/layout'

const routes = [
  {
    path: '/admin/login',
    name: '管理员登录',
    component: () => import('@/views/admin/login'),
    meta: { title: '管理员登录', id: 'login' }
  },
  {
    path: '/admin',
    component: Layout,
    redirect: '/admin/project',
    children: [
      {
        path: '/admin/category',
        name: '类别管理',
        component: () => import('@/views/admin/category'),
        meta: { title: '类别管理', id: 'category' }
      },
      {
        path: '/admin/project',
        name: '项目管理',
        component: () => import('@/views/admin/project'),
        meta: { title: '项目管理', id: 'project' }
      },
      {
        path: '/admin/admin',
        name: '管理员管理',
        component: () => import('@/views/admin/admin'),
        meta: { title: '管理员管理', id: 'admin' }
      },
      {
        path: '/admin/log',
        name: '日志信息',
        component: () => import('@/views/admin/log'),
        meta: { title: '日志信息', id: 'log' }
      }
    ]
  },
  {
    path: '/:lang([a-z]{2})?',
    name: '123Conflux',
    component: () => import('@/views/view/index'),
    meta: { title: '123Conflux', id: 'view' }
  },
  { path: '/:pathMatch(.*)*', redirect: '/', hidden: true }
]

const initRouter = () => {
  return createRouter({
    history: createWebHistory(process.env.BASE_URL),
    // scrollBehavior: () => ({ top: 0 }),
    routes
  });
}
const router = initRouter()

export function resetRouter () {
  const newRouter = initRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
