import request from '@/utils/request'

export function getItems (params) {
  return request({
    url: '/item',
    method: 'get',
    params
  })
}

export function addItem (data) {
  return request({
    url: '/item',
    method: 'post',
    data
  })
}

export function reorderItem (data) {
  return request({
    url: '/item/reorder',
    method: 'post',
    data
  })
}

export function updateItem (data) {
  return request({
    url: '/item/' + data.id,
    method: 'put',
    data
  })
}

export function deleteItem (id) {
  return request({
    url: '/item/' + id,
    method: 'delete'
  })
}