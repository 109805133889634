<template>
  <div
    class="flex app-wrapper"
    :class="{
      desktop: !isMobile,
      openMenu: !isCollapse,
      closeMenu: isCollapse,
      withoutAnimation,
    }"
  >
    <div
      v-show="isMobile && !isCollapse"
      class="overlay"
      @click="handleClickOverlay"
    />
    <div class="flex center topbar-container">
      <topbar class="topbar" />
    </div>
    <div class="sidebar-container" :class="{ show: isMobile && !isCollapse }">
      <sidebar />
    </div>
    <div class="main-container">
      <app-main class="main" />
    </div>
  </div>
</template>

<script>
import { ref, computed, onBeforeMount, onMounted, onBeforeUnmount } from "vue";
import store from "@/store";

import { AppMain, Topbar, Sidebar } from "./components";

export default {
  name: "Layout",
  components: {
    AppMain,
    Topbar,
    Sidebar,
  },
  setup() {
    const WIDTH = 992;

    const isMobile = ref(false);
    const isCollapse = computed(() => {
      return !store.getters.sidebar.isShow;
    });
    const withoutAnimation = computed(() => {
      return store.getters.sidebar.withoutAnimation;
    });

    const handleResize = () => {
      if (document.hidden) return;

      const rect = document.body.getBoundingClientRect();
      isMobile.value = rect.width - 1 < WIDTH;
      store.dispatch("layout/toggleDevice", isMobile.value);
    };
    const handleClickOverlay = () => {
      store.dispatch("layout/closeSideBar", { withoutAnimation: false });
    };

    onBeforeMount(() => {
      // console.log("### onBeforeMount");
      window.addEventListener("resize", handleResize);
    });
    onMounted(() => {
      // console.log("### onMounted");
      handleResize();
    });
    onBeforeUnmount(() => {
      window.removeEventListener("resize", handleResize);
    });

    return {
      isMobile,
      isCollapse,
      withoutAnimation,

      handleClickOverlay,
    };
  },
};
</script>

<style lang="less" scoped>
.app-wrapper {
  position: relative;
  height: 100vh;
  width: 100vw;

  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .main-container {
    transition: width 0.28s;
    margin-top: 50px;
    height: calc(100vh - 50px);
    overflow-y: auto;

    .main {
      height: 100%;
      min-height: calc(100vh - 50px);
      width: 100%;
    }
  }

  .topbar-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    transition: left 0.28s;
  }

  .sidebar-container {
    transition: width 0.28s;
    overflow-x: hidden;
  }

  @media (max-width: 992px) {
    .topbar-container {
      left: 0;
    }

    .main-container {
      width: 100%;
    }

    .sidebar-container {
      position: fixed;
      top: 0;
      bottom: 0;
      left: -210px;
      width: 210px;
      z-index: 1001;
      background: white;
      transition: 0.3s left ease-in-out;
    }
    .sidebar-container.show {
      left: 0;
    }
  }
}

.app-wrapper.desktop.openMenu {
  .topbar-container {
    left: 210px;
  }
  .main-container {
    width: calc(100% - 210px);
  }
  .sidebar-container {
    width: 210px;
  }
}
.app-wrapper.desktop.closeMenu {
  .topbar-container {
    left: 64px;
  }
  .main-container {
    width: calc(100% - 64px);
  }
  .sidebar-container {
    width: 64px;
  }
}
.app-wrapper.withoutAnimation {
  .topbar-container {
    transition: none;
  }
  .main-container {
    transition: none;
  }
  .sidebar-container {
    transition: none;
  }
}
</style>
