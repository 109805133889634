import request from '@/utils/request'

export function uploadImage (imageData, onUploadProgress) {
  return request({
    url: '/file/uploadimage',
    method: 'post',
    data: imageData,
    timeout: 0,
    onUploadProgress
  });
}