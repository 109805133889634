import router from './router'
import { getToken } from '@/utils/auth' // get token from cookie

const whiteList = ['/admin', '/admin/login'] // no redirect whitelist

router.beforeEach((to, from) => {
  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) {
    if (to.path === '/admin/login') {
      return { path: '/admin' }
    } else {
      return
    }
  } else {
    if (to.name === "123Conflux" || whiteList.indexOf(to.path) !== -1) {
      return
    } else {
      return { path: "/admin/login", query: { redirect: encodeURIComponent(to.path) }, replace: true }
    }
  }
})
