<template>
  <div class="component-container flex center topbar">
    <hamburger
      class="hamburger-container"
      :isActive="isMenuShow"
      @onClick="handleShowMenu"
    />
    <breadcrumb class="flex-fill" />
    <language-button class="language" />
    <div class="user">
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <img :src="avatar + '?imageView2/1/w/80/h/80'" class="user-avatar" />
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu class="user-dropdown">
          <el-dropdown-item @click="handleLogout">
            <span>{{ $t("login.logout") }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template> 

<script>
import { computed } from "vue";
import store from "@/store";
import router from "@/router";

import languageButton from "@/components/Button/Language";
import Hamburger from "./Hamburger";
import Breadcrumb from "./Breadcrumb";

export default {
  name: "Topbar",
  components: {
    Hamburger,
    Breadcrumb,
    languageButton,
  },
  setup() {
    const isMenuShow = computed(() => {
      return store.getters.sidebar.isShow;
    });
    const avatar = computed(() => {
      return store.getters.userAvatar || require("@/images/avatar_default.png");
    });

    const handleShowMenu = () => {
      store.dispatch("layout/toggleSideBar");
    };
    const handleLogout = async () => {
      await store.dispatch("user/logout");
      router.push({
        path: "/admin/login",
        query: {
          redirect: encodeURIComponent(router.currentRoute.value.fullPath),
        },
      });
    };

    return {
      isMenuShow,
      avatar,
      handleShowMenu,
      handleLogout,
    };
  },
};
</script>

<style lang="less" scoped>
.component-container.topbar {
  height: 50px;
  width: 100%;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;
  }
  .hamburger-container:hover {
    background: rgba(0, 0, 0, 0.025);
  }

  .language {
    margin-right: 30px;
  }

  .user {
    height: 100%;
    line-height: 50px;

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        position: relative;
        margin-top: 5px;

        .user-avatar {
          width: 40px;
          height: 40px;
          border-radius: 10px;
          cursor: pointer;
        }

        .el-icon-caret-bottom {
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
  }
}

.user-dropdown {
  padding: 0;
}
</style>