import request from '@/utils/request'

export function getNonce (data) {
  return request({
    url: '/Authentication/GetNonce',
    method: 'post',
    data
  })
}

export function login (data) {
  return request({
    url: '/Authentication/login',
    method: 'post',
    data
  })
}

export function getAdmins () {
  return request({
    url: '/Authentication/Administrator',
    method: 'get',
  })
}

export function addAdmin (data) {
  return request({
    url: '/Authentication/AddAdministrator',
    method: 'post',
    data
  })
}

export function removeAdmin (data) {
  return request({
    url: '/Authentication/removeAdministrator',
    method: 'post',
    data
  })
}