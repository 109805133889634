import request from '@/utils/request'

export function getCategories () {
  return request({
    url: '/category',
    method: 'get'
  })
}

export function addCategory (data) {
  return request({
    url: '/category',
    method: 'post',
    data
  })
}

export function updateCategory (data) {
  return request({
    url: '/category/' + data.id,
    method: 'put',
    data
  })
}

export function reorderCategory (data) {
  return request({
    url: '/category/reorder',
    method: 'post',
    data
  })
}