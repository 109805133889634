import store from "@/store";
import { languages } from "@/config";

export const MapCategoryList = (categoryList = []) => {
  return categoryList.map(p => MapCategoryItem(p));
}
export const MapCategoryItem = (category) => {
  const name = {}
  languages.forEach(p => {
    name[p.toLowerCase()] = category["name" + p]
  });

  return {
    id: category.id,
    name,
    isDefault: category.isPredefined,
  }
}
export const MapCategoryFormData = (category, source = {}) => {
  source.id = category?.id;
  languages.forEach(p => {
    source["name" + p] = (category?.name && category.name[p.toLowerCase()]) || "";
  });

  return source;
}

export const MapCategoryOptions = (categoryList = []) => {
  return [{ id: null, name: { cn: "全部", en: "All", ru: "All" } }].concat(MapCategoryList(categoryList));
}
export const MapProjectList = (itemList = []) => {
  let list = [];
  for (let i = 0; i < itemList.length; i++) {
    for (let j = 0; j < itemList[i].items.length; j++) {
      let item = MapProjectItem(itemList[i].items[j], {
        categoryId: itemList[i].id,
        index: j,
        length: itemList[i].items.length
      });
      list.push(item);
    }
  }
  return list;
}
export const MapProjectItem = (item, extra) => {
  let project = {
    id: item.id,
    cover: item.imageViewUrl,
    name: {},
    subtitle: {},
    contact: {},
    description: {},
    url: item.url,
    imageUrl: item.imageUrl,
  };
  languages.forEach(p => {
    project.name[p.toLowerCase()] = item["title" + p] || ""
    project.subtitle[p.toLowerCase()] = item["subtitle" + p] || ""
    project.contact[p.toLowerCase()] = item["contact" + p] || ""
    project.description[p.toLowerCase()] = item["description" + p] || ""
    project[p.toLowerCase()] = item["show" + p.toUpperCase()]
  });
  // Temp: 临时添加俄语为空时显示英语内容
  project.name.ru ||= project.name.en
  project.subtitle.ru ||= project.subtitle.en
  project.contact.ru ||= project.contact.en
  project.description.ru ||= project.description.en

  return {
    ...project,
    ...extra
  };
}
export const MapProjectFormData = (project, source = {}) => {
  source.id = project?.id;
  source.categoryId = project?.categoryId;
  languages.forEach(p => {
    source["title" + p] = (project?.name && project.name[p.toLowerCase()]) || ""
    source["subtitle" + p] = (project?.subtitle && project.subtitle[p.toLowerCase()]) || ""
    source["contact" + p] = (project?.contact && project.contact[p.toLowerCase()]) || ""
    source["description" + p] = (project?.description && project.description[p.toLowerCase()]) || ""
    source["show" + p] = (project && project[p.toLowerCase()] != null) ? project[p.toLowerCase()] : true
  });
  source.url = project?.url || "";
  source.imageUrl = project?.imageUrl || "";
  source.imageSource = null;

  return source;
}

export const MapViewList = (itemList = []) => {
  return itemList.filter(p => p.items.length > 0).map(p => MapViewMenuItem(p));
}
export const MapViewMenuItem = (menu) => {
  const name = {}
  languages.forEach(p => {
    name[p.toLowerCase()] = menu["name" + p]
  });
  // Temp: 临时添加俄语为空时显示英语内容
  name.ru ||= name.en

  const params = "show" + store.getters.lang.toUpperCase()
  return {
    id: menu.id,
    name,
    tag: menu.nameEn,
    type: menu.id === 6 ? "list" : "block",
    items: (menu.items || []).filter(p => p[params]).map(p => MapProjectItem(p, { categoryId: menu.id })),
  }
}

