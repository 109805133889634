import { resetRouter } from '@/router'
import { getToken, setToken, removeToken } from '@/utils/auth'
import Network from '@/network'

const state = {
  token: getToken(),
  address: null,
  avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif'
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_ADDRESS: (state, address) => {
    state.address = address
  }
}

const actions = {
  // user login
  async login () {
    if (state.token) return true
    return await Network.login();
  },

  initUserAddress ({ commit }, account) {
    commit('SET_ADDRESS', account)
  },

  initUserDetail ({ commit }, user) {
    setToken(user.token)
    commit('SET_TOKEN', user.token)
  },

  // user logout
  logout ({ commit }) {
    commit('SET_TOKEN', '')
    removeToken()
    resetRouter()
  },

  // remove token
  resetToken ({ commit }) {
    commit('SET_TOKEN', '')
    removeToken()
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

