<template>
  <div
    ref="page"
    class="page-container view"
    :class="{ mobile: isMobile, overlay: isShowMenu }"
  >
    <div class="topbar">
      <div class="topbar-content">
        <div class="flex center between topbar-inside">
          <i
            v-show="isMobile"
            class="el-icon-s-unfold button clickable"
            @click="handleMenuShow"
          />
          <div class="flex">
            <div class="flex center view-text logo">
              <img class="logo-image" src="@/images/cover_default.png" />
              123Conflux
            </div>
            <div v-show="!isMobile" class="search">
              <div class="flex background">
                <input
                  v-model="listQuery.keyword"
                  class="input"
                  type="text"
                  :placeholder="$t('view.placeholder_search')"
                  @input="handleInput"
                  @keydown.enter="handleFilter"
                />
                <img
                  v-show="listQuery.keyword.length > 0"
                  class="icon"
                  src="@/images/clear.png"
                  @click="handleSearchClear"
                />
              </div>
            </div>
          </div>
          <language-button class="language" />
        </div>
      </div>
    </div>
    <div class="flex main">
      <div
        ref="menu"
        class="sidebar"
        :class="{ mobile: isMobile, show: isShowMenu }"
      >
        <div v-show="isMobile" class="view-text title">123Conflux</div>
        <div class="menu-container">
          <div class="flex-column menu-list">
            <a
              class="text-ellipsis view-text cell clickable"
              :class="{ active: index === menuIndex }"
              v-for="(menu, index) in menuList"
              :key="index"
              :herf="'#' + menu.tag"
              @click="handleClickMenu(menu, index)"
              >{{ menu.name[locale] }}
            </a>
          </div>
        </div>
      </div>
      <div class="flex-space"></div>
      <div class="flex-column main-container">
        <div v-show="isMobile" class="flex filter-container main-item">
          <el-input
            v-model="listQuery.keyword"
            type="search"
            :placeholder="$t('view.placeholder_search')"
            @input="handleInput"
            @change="handleFilter"
          />
        </div>
        <div
          class="main-item"
          v-for="content in menuList"
          :key="content.tag"
          :name="content.tag"
          :id="content.tag"
        >
          <card-list
            v-if="content.type === 'list'"
            class="block"
            :list="content.items"
          />
          <card-block
            v-else-if="content.type === 'block'"
            class="block"
            :title="content.name[locale]"
            :list="content.items"
          />
        </div>
        <div v-show="!isLoading" class="flex-column center main-item footer">
          <div class="flex view-text comment">
            {{ $t("view.support") }}
            <a class="link" href="https://github.com/conflux-fans/Conflux123">
              Github 123Conflux
            </a>
          </div>
          <el-button class="button" type="warning" plain @click="handleCommit">
            {{ $t("view.commit") }}
          </el-button>
        </div>
      </div>
      <div class="flex-space right"></div>
    </div>
    <transition>
      <div
        v-show="isMobile && isShowMenu"
        class="overlay"
        @click="isShowMenu = false"
      />
    </transition>
  </div>
</template>

<script>
import {
  ref,
  toRefs,
  reactive,
  watch,
  watchEffect,
  nextTick,
  onBeforeMount,
  onMounted,
  onBeforeUnmount,
} from "vue";
import { useI18n } from "vue-i18n";
import store from "@/store";
import router from "@/router";

import languageButton from "@/components/Button/Language";
import cardList from "@/components/Card/List";
import cardBlock from "@/components/Card/Block";

import { languages } from "@/config";
import { MapViewList } from "@/utils/mapper";
import { getItems } from "@/api/item";

export default {
  name: "View",
  components: {
    languageButton,
    cardList,
    cardBlock,
  },
  setup() {
    const { locale } = toRefs(useI18n());
    const lang = router.currentRoute.value.params.lang;
    if (languages.findIndex((p) => p.toLowerCase() === lang) > -1) {
      locale.value = lang;
      store.dispatch("layout/setLanguage", locale.value);
    }

    const WIDTH = 960;
    const page = ref(null);
    const menu = ref(null);
    const windowScrollParams = {
      direction: 0,
      offset: 0,
      max: 0,
    };
    const menuScrollParams = {
      startOffset: 0,
      rate: 0,
      startTop: 70,
      offset: 0,
    };

    watch(
      () => store.getters.lang,
      () => {
        showMenuList(fullList.value);
      }
    );

    const isMobile = ref(false);
    watchEffect(() => {
      if (isMobile.value) {
        menuScrollParams.startTop = 0;
        nextTick(() => {
          menu.value.style.top = 0;
        });
      } else {
        menuScrollParams.startTop = 70;
        nextTick(() => {
          menu.value.style.top =
            menuScrollParams.startTop - menuScrollParams.offset + "px";
        });
      }
    });

    const isLoading = ref(true);
    const isShowMenu = ref(false);
    const fullList = ref([]);
    const menuList = ref([]);
    const menuIndex = ref(0);

    const listQuery = reactive({
      keyword: "",
      categoryId: null,
    });
    const searchInterval = 1000;
    let timeoutId = null;

    const getOffsetByHash = (hash) => {
      const target = document.getElementById(hash);
      return target ? target.offsetTop : null;
    };
    const getOffsetByIndex = (index) => {
      index = Math.min(menuList.value.length - 1, index);
      return getOffsetByHash(menuList.value[index].tag);
    };
    const scrollTo = (hash, withoutAnimation = false) => {
      if (!hash) return;

      const start = window.pageYOffset;
      const stop = getOffsetByHash(hash.substring(1));
      if (stop === null || start === stop) return;

      if (
        (start === windowScrollParams.max && stop > start) ||
        withoutAnimation
      ) {
        window.scroll(0, stop);
        // document.getElementById(hash.substring(1)).scrollIntoView();
        // menuIndex.value = index;
      } else {
        const tick = (stop - start) / (1000 / 60);
        scrollWithAnimation(start, stop, tick);
      }
    };
    const scrollWithAnimation = (start, stop, tick) => {
      let current = 0;
      let isFinished = false;
      // 通过+-1来避免精度问题
      if (start > stop) {
        if (start + tick < stop) {
          current = stop - 1;
          isFinished = true;
        } else {
          current = start + tick;
        }
      } else {
        if (start + tick > stop) {
          current = stop + 1;
          isFinished = true;
        } else {
          current = start + tick;
        }
      }

      window.requestAnimationFrame(() => {
        window.scroll(0, current);
        if (!isFinished) scrollWithAnimation(current, stop, tick);
      });
    };

    watchEffect(() => {
      const route = router.currentRoute.value;
      scrollTo(route.hash);
    });

    const handleResize = () => {
      if (document.hidden) return;

      const rect = document.body.getBoundingClientRect();
      isMobile.value = rect.width - 1 < WIDTH;
    };
    onBeforeMount(() => {
      window.addEventListener("resize", handleResize);
    });
    onMounted(() => {
      handleResize();
    });
    onBeforeUnmount(() => {
      window.removeEventListener("resize", handleResize);
    });

    const getMenuList = async (resetScroll) => {
      isLoading.value = true;
      menuList.value = [];
      const response = await getItems(listQuery);
      // console.log("### ", response.content);
      fullList.value = response.content;
      showMenuList(fullList.value, resetScroll);
      nextTick(() => {
        createScrollParams();
        createMenuScrollParams();

        if (resetScroll) {
          window.scroll(0, 0);
          menuIndex.value = 0;
        } else {
          const hash = router.currentRoute.value.hash;
          // console.log("### hash: ", hash);
          // window.location.hash = hash;
          scrollTo(hash, true);
        }
        isLoading.value = false;
      });
    };
    const showMenuList = async (list) => {
      menuList.value = MapViewList(list);
      // console.log("### ", menuList.value);
    };

    const createScrollParams = () => {
      windowScrollParams.max = Math.max(
        page.value.clientHeight - window.innerHeight,
        0
      );
      // console.log("### ", windowScrollParams);
    };
    const createMenuScrollParams = () => {
      //每个菜单按钮的高度
      const menuHeight = 36;
      //当菜单索引到页面倒数第几个时开始滚动
      const scrollCount = 3;
      //当菜单滚到末尾时的下边距
      const Bottom = 70;

      const menuScrollIndex = Math.floor(
        (window.innerHeight - Bottom) / menuHeight - scrollCount
      );
      // console.log("### menuScrollIndex: ", menuScrollIndex);
      menuScrollParams.startOffset = getOffsetByIndex(menuScrollIndex);
      const scrollDistance =
        windowScrollParams.max - menuScrollParams.startOffset;
      const maxTop = window.innerHeight - menu.value.clientHeight - Bottom;
      menuScrollParams.rate =
        (menuScrollParams.startTop - maxTop) / scrollDistance;
      // console.log("### menuScrollParams: ", menuScrollParams);
    };

    const handleMenuShow = () => {
      if (!isMobile.value) return false;
      isShowMenu.value = !isShowMenu.value;
    };
    const handleClickMenu = (menu, index) => {
      if (index === menuIndex.value) return;

      isShowMenu.value = false;
      router.replace("/#" + menu.tag);
      // window.location.hash = "#" + menu.tag;
      // document.getElementById(menu.tag).scrollIntoView();
    };

    const handleInput = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
      timeoutId = setTimeout(() => {
        handleFilter();
      }, searchInterval);
    };
    const handleFilter = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
      // isLoading.value = true;
      getMenuList(true);
    };
    const handleSearchClear = () => {
      listQuery.keyword = "";
      handleFilter();
    };
    const handleCommit = () => {
      window.open("https://github.com/conflux-fans/Conflux123/issues");
    };

    getMenuList();

    window.onscroll = function () {
      if (window.pageYOffset === windowScrollParams.offset) return;

      windowScrollParams.direction =
        window.pageYOffset > windowScrollParams.offset ? 1 : -1;
      windowScrollParams.offset = window.pageYOffset;
      getMenuIndex(windowScrollParams);
      getMenuTop(window.pageYOffset - menuScrollParams.startOffset);
    };

    const getMenuIndex = (params) => {
      let index = menuIndex.value;
      if (params.direction > 0) {
        // 通过将offset+1来避免精度问题
        while (
          index + 1 < menuList.value.length &&
          getOffsetByIndex(index + 1) <= params.offset + 1
        ) {
          index++;
        }
      } else if (params.direction < 0) {
        // 通过将offset-1来避免精度问题
        while (index > 0 && getOffsetByIndex(index - 1) >= params.offset - 1) {
          index--;
        }
      }
      menuIndex.value = index;
    };
    const getMenuTop = (offset) => {
      menuScrollParams.offset = offset > 0 ? offset * menuScrollParams.rate : 0;
      menu.value.style.top =
        menuScrollParams.startTop - menuScrollParams.offset + "px";
    };

    return {
      locale,
      page,
      menu,
      isMobile,
      isLoading,
      isShowMenu,
      menuList,
      menuIndex,
      listQuery,
      handleMenuShow,
      handleClickMenu,
      handleInput,
      handleFilter,
      handleSearchClear,
      handleCommit,
    };
  },
};
</script>

<style lang="less" scoped>
.page-container.view {
  min-height: calc(100vh - 100px);
  padding-left: 0;
  padding-right: 0;

  @media (min-width: 600px) {
    .main {
      padding-left: 24px;
      padding-right: 24px;
    }

    .topbar {
      .topbar-content {
        .topbar-inside {
          padding-left: 24px;
          padding-right: 24px;
        }
      }
    }
  }

  @media (min-width: 960px) {
    .main {
      .sidebar {
        opacity: 0;
      }
    }
  }

  @media (min-width: 1280px) {
    .main {
      max-width: 1280px;

      .sidebar {
        opacity: 1 !important;
      }
      .flex-space.right {
        display: none !important;
      }
    }

    .topbar {
      .topbar-content {
        max-width: 1280px;
      }
    }
  }

  .main {
    height: 100%;
    margin: 0 auto;
    box-sizing: border-box;

    .sidebar {
      position: fixed;
      top: 70px;
      max-width: 200px;
      overflow: hidden;

      .title {
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid lightgray;
        text-align: center;
      }

      .menu-list {
        position: relative;
        padding: 0 8px;
        // height: 100%;

        .cell {
          position: relative;
          padding: 8px 16px;
          height: 20px;
          line-height: 20px;
          width: 100%;
          overflow: hidden;
        }
        .cell.clickable:hover {
          background-color: rgba(255, 140, 20, 0.15);
        }
        .cell.active::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -5px;
          height: 12px;
          width: 4px;
          background-color: #ff7828;
        }
      }
    }

    .main-container {
      width: 1042px;
      padding-bottom: 50px;

      .filter-container {
        z-index: 10;

        .filter-item {
          min-width: 100px;
          width: 200px;
          margin-bottom: 5px;
          margin-right: 5px;
        }
        .filter-item.button {
          width: auto;
        }
      }

      .main-item {
        padding-top: 50px;
        margin-bottom: -34px;

        .block {
          width: 100%;
        }
      }

      .footer {
        .comment {
          margin-bottom: 10px;

          .link {
            margin-left: 5px;
            color: gray;
          }
        }

        .button {
          color: #ff7828;
          border-color: #ff7828;
        }
        .button:active,
        .button:hover {
          background: #ff7828;
          border-color: #ff7828;
          color: #fff;
        }
      }
    }
  }

  .topbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    background: white;
    z-index: 20;
    box-shadow: 2px 2px 10px 0px rgba(104, 104, 104, 0.2);

    .topbar-content {
      height: 100%;
      margin: 0 auto;

      .topbar-inside {
        height: 100%;
      }
    }

    .logo-image {
      height: 24px;
      width: 24px;
      margin-right: 5px;
    }

    .search {
      position: relative;
      height: 36px;
      width: 300px;
      margin-left: 36px;

      .background {
        height: 100%;
        background: #e7e7e79c;
        border-radius: 5px;

        .icon {
          margin: 3px 5px;
        }

        .input {
          width: 100%;
          padding: 6px 0 6px 15px;
          font-size: 14px;
          //   color: #999999;
          color: black;
          border: none;
          background-color: transparent;
        }

        input::-webkit-input-placeholder {
          color: #999;
        }
      }
    }

    .button {
      font-size: 30px;
      color: black;
      // opacity: 0;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
  }
}
.view.mobile {
  padding-left: 16px;
  padding-right: 16px;

  .main {
    .sidebar {
      // position: fixed;
      top: 0;
      bottom: 0;
      left: -200px;
      z-index: 100;
      width: 200px;
      background: white;
      border-right: 1px solid lightgray;
      transition: 0.3s left ease-in-out;

      .menu-container {
        height: calc(100% - 50px);
        overflow-y: auto;

        .menu-list {
          padding-bottom: 20px;

          .cell {
            width: calc(100% - 32px);
          }
        }
      }
    }
    .sidebar.show {
      left: 0;
    }
  }
  .topbar {
    padding: 0 16px;

    // .topbar-content {
    //   .topbar-inside {
    //     .button {
    //       opacity: 1;
    //     }
    //   }
    // }
  }
}
.view.mobile.overlay {
  height: calc(100vh - 100px);
  overflow: hidden;
}

.view-text.logo {
  font-size: 24px;
  font-weight: bold;
  color: black;
}
.view-text.title {
  font-size: 18px;
  font-weight: bold;
  color: black;
}
.view-text.cell:hover,
.view-text.cell.active {
  font-weight: 500;
  color: #ff7828;
}

.view-text.comment {
  font-size: 12px;
  color: gray;
}
</style>


