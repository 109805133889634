import Network from "@/network"
import adminAbi from '@/abi/Artemisia.json'

// const adminCon = Network && Network.instance.Contract({ address: process.env.VUE_APP_CONTRACT_ADMIN, abi: adminAbi })
const adminConPor = window.confluxJS && window.confluxJS.Contract({ address: process.env.VUE_APP_CONTRACT_ADMIN, abi: adminAbi })

export default {
  login: (nonce) => {
    return Network.sendTransaction(adminConPor.setLoginNonce(nonce))
  },
}