<template>
  <div class="component-container sidebar">
    <el-menu
      class="menu"
      background-color="#304156"
      text-color="#bfcbd9"
      active-text-color="#409EFF"
      :collapse="isCollapse"
      :collapse-transition="false"
      :default-active="activeMenu"
      router
    >
      <el-menu-item
        v-for="route in routes"
        :key="route.path"
        class="item"
        :index="route.path"
        @click="handleMenuClick"
      >
        <i class="el-icon-menu"></i>
        <template v-slot:title>
          {{ isCollapse ? "" : $t("route." + route.meta.id) }}
        </template>
      </el-menu-item>
    </el-menu>
  </div>
</template> 

<script>
import { ref, computed } from "vue";
import router from "@/router";
import store from "@/store";

export default {
  name: "Topbar",
  setup() {
    const getRoutes = () => {
      const adminRoutes = router.options.routes.find((p) => {
        return p.path === "/admin";
      });
      return adminRoutes.children;
    };
    const routes = getRoutes();
    const activeMenu = ref(router.currentRoute.value.path);

    const isCollapse = computed(() => {
      return !store.getters.sidebar.isShow;
    });

    const handleMenuClick = () => {
      if (store.getters.isMobile) {
        store.dispatch("layout/toggleSideBar");
      }
    };

    return {
      routes,
      activeMenu,
      isCollapse,

      handleMenuClick,
    };
  },
};
</script>

<style lang="less" scoped>
.component-container.sidebar {
  overflow: hidden;
  height: 100%;

  .menu {
    height: 100%;
    width: 210px;
    transition: width 0.28s !important;

    .item:hover {
      background-color: #263445 !important;
    }
  }
}
</style>