<template>
  <el-popover placement="bottom-start" width="500" trigger="click">
    <div class="calendar-contaienr">
      <el-calendar class="calendar" v-model="date" />
    </div>
    <template #reference>
      <div class="component-container selector-date">
        <el-input
          v-model="selectedDate"
          :placeholder="placeholder"
          suffix-icon="el-icon-date"
          readonly
        />
      </div>
    </template>
  </el-popover>
</template>

<script>
import { ref, watch, watchEffect, toRefs } from "vue";
import { parseTime } from "@/utils";

export default {
  name: "Selector_Date",
  props: {
    selectedDate: String,

    format: {
      type: String,
      default: "{y}-{m}-{d}",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  emits: ["onChange"],
  setup(props, context) {
    const { selectedDate, format, placeholder } = toRefs(props);

    const date = ref(null);

    watchEffect(() => {
      if (selectedDate.value) date.value = new Date(selectedDate.value);
      else date.value = new Date();
    });
    watch(date, () => {
      context.emit("update:selectedDate", parseTime(date.value, format.value));
    });

    return {
      placeholder,
      date,
      selectedDate,
    };
  },
};
</script>

<style lang="less">
.component-container.selector-date {
  .el-input {
    input {
      cursor: pointer;
    }
  }
}
</style>

<style lang="less" scoped>
.calendar-contaienr {
  height: 400px;
  padding: 20px;
  overflow-y: auto;
}
</style>