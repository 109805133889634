
export const common = {
  // language: 'English',
  language: '中文',
  yes: "是",
  no: "否",
  sure: "确认",
  cancel: "取消",
  hint: "提示",
}

export const language = {
  Cn: "中文",
  En: "英文",
  Ru: "俄文",
}

export const route = {
  login: '管理员登录',
  category: "类别管理",
  project: "项目管理",
  admin: "管理员管理",
  log: "日志信息",
}

export const view = {
  placeholder_search: "搜索",
  support: "本网站所有数据均来自于",
  commit: "提交新项目",
}

export const login = {
  address_default: "选择帐号",
  address_running: "选择帐号中",
  login_default: "授权登录",
  login_running: "Fluent授权中",
  logout: "登出",
}

export const table = {
  name: "名称",
  subtitle: "副标题",
  cover: "封面",
  address: "账户",
  url: "地址",
  description: "描述",
  isDefault: "是否为默认类别",
  lastLogin: "最后登录时间",
  time: "时间",
  operation: "操作",
  operator: "操作者",
  source: "操作源",
  target: "数据对象",
  action: "操作",
  up: "上移",
  down: "下移",
  edit: "编辑",
  remove: "移除",
  delete: "删除",
}

export const form = {
  name: "{0}名称",
  subtitle: "{0}副标题",
  contact: "{0}联系方式",
  description: "{0}描述",
  show: "在{0}环境下显示",
  address: "账户地址",
  category: "类别",
  url: "网址",
  imageurl: "图片地址",
  search: "搜索",
  placeholder_address: "请选择Conflux帐号",
  placeholder_search: "请输入搜索内容",
  placeholder_beginDate: "请选择起始日期",
  placeholder_endDate: "请选择结束日期",
}

export const category = {
  add: '新建类别',
  edit: '编辑类别',
  name_input: '请输入类别的{0}名称',
}

export const project = {
  add: '新建项目',
  edit: '编辑项目',
  delete_confirm: "是否确认删除项目：",
  delete_completed: "项目已删除",
  category_select: "请选择项目类别",
  cover_type: "暂不支持该格式的图片，请重新选择",
  cover_size: "图片大小不能超过 2MB",
  url_input: "请输入项目地址",
  title_input: '请输入项目的{0}名称',
}

export const admin = {
  add: '添加管理员',
  remove_confirm: "是否确认移除管理员：",
  remove_completed: "已将管理员移除",
  address_input: "请输入账户地址",
}

export const api = {
  error: {
    "101": "登录已失效，请重新登录",
  },
  hint: {
    "101": "登出"
  }
}