<template>
  <div class="component-container card-list">
    <div class="flex wrap card-content">
      <div v-for="(project, index) in list" :key="index" class="card">
        <a
          v-if="project.url"
          class="flex-column center project-container link clickable"
          :href="project.url"
        >
          <div class="flex center project">
            <img class="icon" :src="getCover(project.cover)" />
            <div class="text-ellipsis-2 card-list-text card-title">
              {{ project.name[locale] }}
            </div>
          </div>
        </a>
        <div v-else class="flex-column center project-container clickable">
          <div class="flex center project">
            <img class="icon" :src="getCover(project.cover)" />
            <div class="text-ellipsis-2 card-list-text card-title">
              {{ project.name[locale] }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, toRefs } from "vue";
import store from "@/store";

export default {
  name: "Block_Cards",
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup(props) {
    const { list } = toRefs(props);
    const locale = computed(() => {
      return store.getters.lang;
    });
    const getCover = (cover) => {
      return cover || require("@/images/cover_default.png");
    };

    return {
      locale,
      list,
      getCover,
    };
  },
};
</script>

<style lang="less" scoped>
.component-container.card-list {
  width: 100%;

  .card-content {
    box-sizing: border-box;
    width: calc(100% + 16px);
    margin: -8px;
  }

  .card {
    box-sizing: border-box;
    padding: 8px;
    max-width: 50%;
    flex-basis: 50%;

    .link {
      text-decoration: none;
    }

    .project-container {
      width: 100%;
      background: white;
      border-radius: 8px;
      text-decoration: none;

      .project {
        height: 60px;
        max-width: 90%;
        padding: 16px 0;

        .icon {
          margin-right: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .component-container.card-list {
    .card {
      .project {
        height: 46px;
        width: 165.31px;
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .component-container.card-list {
    .card {
      max-width: 33% !important;
      flex-basis: 33% !important;

      .project-container:hover {
        box-shadow: 0 8px 32px 0 #cdd3df;
      }

      .project {
        height: 60px;
      }
    }
  }
}

@media screen and (min-width: 960px) {
  .component-container.card-list {
    .card {
      max-width: 25% !important;
      flex-basis: 25% !important;
    }
  }
}

.card-list-text.card-title {
  font-size: 18px;
  font-weight: bold;
  color: black;
  word-break: keep-all;
}
</style>


