export const common = {
  language: 'English',
  // language: '中文',
  yes: "Yes",
  no: "No",
  sure: "Sure",
  cancel: "Cancel",
  hint: "Hint",
}

export const language = {
  Cn: "Chinese",
  En: "English",
  Ru: "Russian",
}

export const route = {
  login: 'AdminLogin',
  category: "CategoryManage",
  project: "ProjectManage",
  admin: "AdminManage",
  log: "Log",
}

export const view = {
  placeholder_search: "Search",
  support: "All data is supported by",
  commit: "New Project",
}

export const login = {
  address_default: "Select Address",
  address_running: "Fluent Logining ",
  login_default: "Login",
  login_running: "Authenticating",
  logout: "logout",
}

export const table = {
  name: "name",
  subtitle: "subtitle",
  cover: "cover",
  address: "address",
  url: "url",
  description: "description",
  isDefault: "isDefaultCategory",
  lastLogin: "latestLoginTime",
  time: "time",
  operation: "operation",
  operator: "operator",
  source: "source",
  target: "target",
  action: "action",
  up: "up",
  down: "down",
  edit: "edit",
  remove: "remove",
  delete: "delete",
}

export const form = {
  name: '{0} Name',
  subtitle: "{0} Subtitle",
  contact: "{0} Contact",
  description: "{0} Description",
  show: "Show in {0}",
  address: "Address",
  category: "Category",
  url: "URL",
  imageurl: "Image URL",
  search: "Search",
  placeholder_address: "Choose your conflux address",
  placeholder_search: "Search",
  placeholder_beginDate: "Begin Date",
  placeholder_endDate: "End Date",
}

export const category = {
  add: 'Add',
  edit: 'Edit',
  name_input: 'you need to input the {0} name',
}

export const project = {
  add: 'Add',
  edit: 'Edit',
  delete_confirm: "Are you sure to delete project: ",
  delete_completed: "Project has been deleted",
  category_select: "you need to choose a category",
  cover_type: "this image is not supported, please choose another one",
  cover_size: "image size cannot larger than 2MB",
  url_input: "you need to input the url",
  title_input: 'you need to input the {0} name',
}

export const admin = {
  add: 'Add',
  remove_confirm: "Are you sure to remove admin: ",
  remove_completed: "Admin has been removed",
  address_input: "you need to input the address",
}

export const api = {
  error: {
    "101": "You must re-login",
  },
  hint: {
    "101": "Logout"
  }
}