<template>
  <div class="component-container data-category">
    <el-form
      ref="form"
      class="form"
      :model="categoryForm"
      :rules="rules"
      label-width="100"
    >
      <el-form-item
        v-for="lang in languages"
        :key="lang"
        :label="$t('form.name', [$t('language.' + lang)])"
        :prop="'name' + lang"
      >
        <el-input v-model="categoryForm['name' + lang]" />
      </el-form-item>
      <el-form-item class="flex-row-reverse actions">
        <el-button @click="handleCancel">{{ $t("common.cancel") }}</el-button>
        <el-button type="primary" @click="handleSubmit">
          {{ $t("common.sure") }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { Message } from "element3";

import { ref, reactive, watchEffect, toRefs } from "vue";
import { useI18n } from "vue-i18n";
import { languages } from "@/config";
import { MapCategoryFormData } from "@/utils/mapper";

export default {
  name: "DataForm_Category",
  props: {
    category: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  emits: ["onSubmit", "onCancel"],
  setup(props, context) {
    const { t } = useI18n();

    const { category } = toRefs(props);

    const form = ref(null);
    const categoryForm = reactive({});
    watchEffect(() => {
      MapCategoryFormData(category.value, categoryForm);
    });
    const rules = {};
    languages.forEach((p) => {
      rules["name" + p] = [
        {
          required: true,
          message: t("category.name_input", [t("language." + p)]),
          trigger: "blur",
        },
      ];
    });

    const handleSubmit = async () => {
      // try {
      //   await form.value.validate();
      //   console.log("### categoryForm: ", categoryForm);
      // } catch (error) {
      //   console.log("### ", error);
      // }

      let result = true;
      languages.forEach((p) => {
        if (result && !categoryForm["name" + p]) {
          Message({
            showClose: true,
            message: t("category.name_input", [t("language." + p)]),
            type: "warning",
          });
          result = false;
        }
      });
      if (result) context.emit("onSubmit", { data: { ...categoryForm } });
    };

    const handleCancel = () => {
      context.emit("onCancel");
    };
    // const handleReset = () => {
    //   form.value.resetFields();
    // };

    return {
      languages,

      form,

      categoryForm,
      rules,

      handleSubmit,
      handleCancel,
    };
  },
};
</script>

<style lang="less" scoped>
.component-container.data-category {
  height: 100%;
  width: 100%;
  overflow-y: auto;

  .form {
    .actions {
      margin-bottom: 0;
    }
  }
}
</style>