import { getLanguage, setLanguage } from '@/utils/auth'

const state = {
  sidebar: {
    isShow: true,
    withoutAnimation: false
  },
  isMobile: false,
  language: getLanguage() || process.env.VUE_APP_LANGUAGE
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.isShow = !state.sidebar.isShow
    state.sidebar.withoutAnimation = false
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    state.sidebar.isShow = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state, isMobile) => {
    state.isMobile = isMobile
  },
  SET_LANGUAGE: (state, language) => {
    state.language = language
  }
}

const actions = {
  toggleSideBar ({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar ({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice ({ commit }, isMobile) {
    if (isMobile === state.isMobile) return;

    commit('TOGGLE_DEVICE', isMobile)
    if (isMobile) {
      this.dispatch("layout/closeSideBar", { withoutAnimation: true });
    }
    else {
      console.log("### sidebar: ", state.sidebar)
    }
  },

  setLanguage ({ commit }, language) {
    setLanguage(language)
    commit('SET_LANGUAGE', language)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
