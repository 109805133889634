import Vuex from 'vuex'
import user from './modules/user'
import layout from './modules/layout'
import getters from './getters'

const store = new Vuex.Store({
  modules: {
    user,
    layout
  },
  getters
})

export default store
