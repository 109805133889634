<template>
  <div class="page-container category">
    <div class="title">{{ $t("route.project") }}</div>
    <div class="flex between filter-container">
      <div class="flex">
        <div class="filter-item">
          <el-input
            v-model="listQuery.keyword"
            :placeholder="$t('form.placeholder_search')"
            :clearable="listQuery.keyword.length > 0"
            @keyup.enter="handleFilter"
            @clear="handleFilter"
          />
        </div>
        <div class="filter-item">
          <el-select v-model="listQuery.categoryId" @change="handleFilter">
            <el-option
              v-for="item in categoryList"
              :key="item.id"
              :label="item.name[locale]"
              :value="item.id"
            />
          </el-select>
        </div>
        <el-button
          class="filter-item button"
          type="primary"
          icon="el-icon-search"
          @click="handleFilter"
          >{{ $t("form.search") }}</el-button
        >
      </div>
      <el-button
        class="filter-item button"
        type="primary"
        icon="el-icon-plus"
        @click="handleAdd"
        >{{ $t("project.add") }}</el-button
      >
    </div>
    <el-table
      v-loading="isLoading"
      class="table"
      :data="projectList"
      border
      fit
      highlight-current-row
    >
      <el-table-column :label="$t('table.cover')" align="center" width="120">
        <template v-slot="{ row }">
          <div class="flex center">
            <img class="cover" :src="getDisplayCover(row.cover)" />
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('table.name')" align="center" min-width="120">
        <template v-slot="{ row }">
          <span>{{ row.name ? row.name[locale] : "" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('table.subtitle')"
        align="center"
        min-width="120"
      >
        <template v-slot="{ row }">
          <span>{{ row.subtitle ? row.subtitle[locale] : "" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('table.description')"
        align="center"
        min-width="120"
      >
        <template v-slot="{ row }">
          <span>{{ row.description ? row.description[locale] : "" }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('table.url')" align="center" min-width="120">
        <template v-slot="{ row }">
          <a :href="row.url">{{ row.url }}</a>
        </template>
      </el-table-column>
      <el-table-column :label="$t('table.action')" align="center" width="100px">
        <template v-slot="{ row }">
          <div>
            <div>
              <el-button
                type="text"
                size="small"
                :disabled="row.index === 0"
                @click="handleReorder(row.id, row.index - 1)"
              >
                {{ $t("table.up") }}
              </el-button>
              <el-button type="text" size="small" @click="handleEdit(row)">
                {{ $t("table.edit") }}
              </el-button>
            </div>
            <div>
              <el-button
                type="text"
                size="small"
                :disabled="row.index >= row.length - 1"
                @click="handleReorder(row.id, row.index + 1)"
              >
                {{ $t("table.down") }}
              </el-button>

              <el-button type="text" size="small" @click="handleDelete(row)">
                {{ $t("table.delete") }}
              </el-button>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="space"></div>
    <el-dialog
      v-model:visible="isShowDialog"
      :title="projectQuery.title"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close
      @closed="handleDialogClosed"
    >
      <template v-slot:default>
        <project-form
          :categoryList="categoryList"
          :project="projectQuery.data"
          @onSubmit="handleSubmit"
          @onCancel="isShowDialog = false"
        />
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, computed } from "vue";
import { useI18n } from "vue-i18n";
import store from "@/store";

import { Msgbox, Message } from "element3";
import projectForm from "@/components/DataForm/Project";

import { MapCategoryOptions, MapProjectList } from "@/utils/mapper";

import { getCategories } from "@/api/category";
import {
  getItems,
  reorderItem,
  addItem,
  updateItem,
  deleteItem,
} from "@/api/item";

export default {
  name: "Project",
  components: {
    projectForm,
  },
  setup() {
    const { t } = useI18n();
    const locale = computed(() => {
      return store.getters.lang;
    });

    const isLoading = ref(true);
    const isShowDialog = ref(false);

    const listQuery = reactive({
      keyword: "",
      categoryId: null,
    });
    const categoryList = ref(MapCategoryOptions());
    const projectList = ref([]);
    const projectQuery = ref({});

    const getDisplayCover = (cover) => {
      return cover || require("@/images/cover_default.png");
    };

    const getCategoryList = async () => {
      const response = await getCategories();
      // console.log("###", response.content);
      categoryList.value = MapCategoryOptions(response.content);
    };
    const getProjectList = async () => {
      const response = await getItems(listQuery);
      // console.log("###", response.content);
      projectList.value = MapProjectList(response.content);
      // console.log("### ", projectList.value);

      isLoading.value = false;
    };

    const handleFilter = () => {
      isLoading.value = true;
      getProjectList();
    };
    const handleReorder = async (projectId, index) => {
      console.log("### projectId: ", projectId);
      console.log("### index: ", index);
      await reorderItem({
        id: projectId,
        index,
      });
      handleFilter();
    };
    const handleAdd = () => {
      projectQuery.value = {
        title: t("project.add"),
        data: { categoryId: listQuery.categoryId, cn: true, en: true },
      };
      isShowDialog.value = true;
    };
    const handleEdit = (item) => {
      projectQuery.value = {
        title: t("project.edit"),
        data: item,
      };
      isShowDialog.value = true;
    };
    const handleDelete = async (item) => {
      try {
        await Msgbox.confirm(
          t("project.delete_confirm") + item.name[locale.value],
          t("common.hint"),
          {
            confirmButtonText: t("common.sure"),
            cancelButtonText: t("common.cancel"),
            type: "warning",
          }
        );
        await deleteItem(item.id);
        Message({
          type: "success",
          message: t("project.delete_completed"),
        });
        handleFilter();
      } catch (error) {
        console.log("### ", error);
      }
    };

    const handleSubmit = async (e) => {
      if (e.data.id) {
        await updateItem(e.data);
      } else {
        await addItem(e.data);
        listQuery.categoryId = e.data.categoryId;
      }
      isShowDialog.value = false;
      handleFilter();
    };
    const handleDialogClosed = () => {
      projectQuery.value = {};
    };

    getCategoryList();
    getProjectList();

    return {
      locale,
      isLoading,
      isShowDialog,

      listQuery,
      categoryList,
      projectList,
      projectQuery,

      getDisplayCover,
      handleFilter,
      handleReorder,
      handleAdd,
      handleEdit,
      handleDelete,
      handleSubmit,
      handleDialogClosed,
    };
  },
};
</script>


<style lang="less">
.el-dialog {
  display: flex;
  flex-direction: column;
  width: 80%;
  min-width: 600px;
  max-height: 90vh;
  margin: 5vh auto !important;
  overflow: hidden;

  .el-dialog__body {
    padding-top: 10px;
    overflow-y: auto;
  }
}
</style>

<style lang="less" scoped>
.page-container.category {
  .filter-container {
    padding-bottom: 10px;

    .filter-item {
      min-width: 100px;
      width: 200px;
      margin-bottom: 5px;
      margin-right: 5px;
    }
    .filter-item.button {
      width: auto;
    }
  }

  .table {
    width: 100%;

    .cover {
      width: 100%;
      height: 100%;
    }
  }

  .space {
    height: 20px;
  }
}
</style>